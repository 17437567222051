/* eslint-disable max-len */
function OpenInNew() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1390_6789)">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.1998 1.40005C11.1998 1.06868 11.4684 0.800049 11.7998 0.800049H14.5998C14.9312 0.800049 15.1998 1.06868 15.1998 1.40005V4.20005C15.1998 4.53142 14.9312 4.80005 14.5998 4.80005C14.2684 4.80005 13.9998 4.53142 13.9998 4.20005V2.84858L9.22407 7.62431C8.98975 7.85863 8.60986 7.85863 8.37554 7.62431C8.14123 7.39 8.14123 7.0101 8.37554 6.77578L13.1513 2.00005H11.7998C11.4684 2.00005 11.1998 1.73142 11.1998 1.40005ZM0.799805 3.80005C0.799805 2.58502 1.78478 1.60005 2.9998 1.60005H7.1998C7.53118 1.60005 7.7998 1.86868 7.7998 2.20005C7.7998 2.53142 7.53118 2.80005 7.1998 2.80005H2.9998C2.44752 2.80005 1.9998 3.24776 1.9998 3.80005V13C1.9998 13.5523 2.44752 14 2.9998 14H12.1998C12.7521 14 13.1998 13.5523 13.1998 13V8.80005C13.1998 8.46868 13.4684 8.20005 13.7998 8.20005C14.1312 8.20005 14.3998 8.46868 14.3998 8.80005V13C14.3998 14.2151 13.4148 15.2 12.1998 15.2H2.9998C1.78478 15.2 0.799805 14.2151 0.799805 13V3.80005Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1390_6789">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default OpenInNew;
