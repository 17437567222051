/* eslint-disable max-len */
function LinkCopyIcon() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1394_1019)">
        <path d="M15.6875 0C17.2408 0 18.5 1.2592 18.5 2.8125V10.6875C18.5 12.2408 17.2408 13.5 15.6875 13.5H14V15.375C14 16.7845 12.8891 17.9345 11.4952 17.9973L11.375 18H3.125C1.71552 18 0.565504 16.8891 0.502701 15.4952L0.5 15.375V7.125C0.5 5.71552 1.61087 4.5655 3.00484 4.5027L3.125 4.5H5V2.8125C5 1.2592 6.2592 0 7.8125 0H15.6875ZM5 5.625H3.125C2.32844 5.625 1.67689 6.24591 1.62795 7.03014L1.625 7.125V15.375C1.625 16.1716 2.24591 16.8231 3.03014 16.872L3.125 16.875H11.375C12.1716 16.875 12.8231 16.2541 12.872 15.4699L12.875 15.375V13.5H7.8125C6.2592 13.5 5 12.2408 5 10.6875V5.625ZM15.6875 1.125H7.8125C6.9138 1.125 6.17919 1.82752 6.12786 2.71335L6.125 2.8125V10.6875C6.125 11.5862 6.82752 12.3208 7.71335 12.3721L7.8125 12.375H15.6875C16.5862 12.375 17.3208 11.6725 17.3721 10.7867L17.375 10.6875V2.8125C17.375 1.9138 16.6725 1.17919 15.7867 1.12786L15.6875 1.125Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1394_1019">
          <rect width="18" height="18" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default LinkCopyIcon;
